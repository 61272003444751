#wrapper{
    display: flex;
    flex-direction: column;
}

#contentWrapper{
    flex: 1;
}

html {
    font-size: 62.5%;
}

