footer {
    width: 100%;
    height: 5%;
    position: relative;
    bottom: 0px;
    background: #343a40;
    color : #fff;
    text-align: center;
    font-family: "Noto Sans Bold";
    padding-top: 2%;
    padding-bottom: 2%;
}

@media (max-width: 768px) {
    /* 모바일 화면에서 Footer가 Main 컨텐츠 아래에 배치되도록 스타일 조정 */
    .footer {
      position: relative; /* Footer의 위치를 상대적으로 설정 */
      width: 100%;
    }
  }