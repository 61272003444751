section {
	padding-top: 150px;
}

.container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-right: 3%;
	padding-left: 3%;
}

span {
	padding-right: 3%;
	font-family: "Noto Sans KR Bold";
	font-size: large;
	font-style: bold;
}

image {
    width: 100%;
    height: 400;
    object-fit: cover;
}

.ImageBox {
    display: flex;
    justify-content: center;
    align-items: center;
	padding-bottom: 3%;
}

.swiper-container {
	border:5px solid silver;
	border-radius:7px;
	box-shadow:0 0 20px #ccc inset;
}
.swiper-slide {
	text-align:center;
	display:flex; /* 내용을 중앙정렬 하기위해 flex 사용 */
	align-items:center; /* 위아래 기준 중앙정렬 */

	justify-content:center; /* 좌우 기준 중앙정렬 */
}
.swiper-slide img {
	box-shadow:0 0 5px #555;
	max-width:100%; /* 이미지 최대너비를 제한, 슬라이드에 이미지가 여러개가 보여질때 필요 */
	/* 이 예제에서 필요해서 설정했습니다. 상황에따라 다를 수 있습니다. */
}

span {
	font-family: "Noto Sans KR";
	font-style: normal;
	font-size: 1.5rem;
}