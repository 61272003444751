// 기본 폰트
@font-face {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 500;
    src: url("../../font/NotoSansKR-Regular.ttf");
}

// 볼드체
@font-face {
    font-family: "Noto Sans KR Bold";
    font-style: normal;
    font-weight: bold;
    src: url("../../font/NotoSansKR-Bold.ttf");
}

// 얇은거
@font-face {
    font-family: "Noto Sans KR Light";
    font-style: normal;
    font-weight: lighter;
    src: url("../../font/NotoSansKR-Light.ttf");
}