header {
    margin-top: 1%;
    margin-bottom: 1cqh;
    width: 100%;
    font-family: "Noto Sans Bold";
    background-color: white;
  
    .inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 80px;
      padding-left: 3%;
      padding-right: 3%;
  
      nav {
        ul {
          display: flex;
          justify-content: space-between;
          align-items: center;
          list-style-type: none;
          
          li {
            font-family: "Noto Sans KR";
            color: black;
            padding-left: 30px;
          }
        }
      }

      a {
        color: black;
        font-family: "Noto Sans KR";
        font-size: large;
        font-weight: bold;
        text-decoration: none;
        :visited {
          text-decoration: none;
        }

        :hover {
          text-decoration: none;
        }
      }
    }
  }